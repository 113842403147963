<template>
  <v-card>
    <h3 class="subheading font-weight-bold text-left">
      Permissions
    </h3>

    <v-container class="pt-6 px-0 pb-0">
      <v-row align="center" justify="start">
        <v-col v-for="(selection, i) in selections" :key="selection.text" class="shrink">
          <v-chip :disabled="loading" close @click:close="selectedPermissions.splice(i, 1)">
            <v-icon left v-text="selection.icon" />
            {{ selection.text }}
          </v-chip>
        </v-col>
        <v-col v-if="!allSelected" cols="12" class="pt-0">
          <v-text-field ref="search" v-model="search" full-width hide-details label="Search" class="pt-0" single-line />
        </v-col>
      </v-row>
    </v-container>
    <v-list>
      <v-treeview :active.sync="active" :items="items" :open.sync="open" activatable
                  color="warning" open-on-click transition>
        <template v-for="item in categories">
          <v-list-item v-if="!selectedPermissions.includes(item)" :key="item.text" :disabled="loading"
                       @click="selectedPermissions.push(item)">
            <v-list-item-avatar>
              <v-icon :disabled="loading" v-text="item.icon" />
            </v-list-item-avatar>
            <v-list-item-title v-text="item.text" class="text-left" />
          </v-list-item>
        </template>
      </v-treeview>
    </v-list>

    <v-card-actions>
      <v-spacer />
      <v-btn :loading="loading" color="primary" large text @click="save(propValue)">
        <!-- <v-btn :loading="loading" color="primary" large text @click="save(selected.id)"> -->
        save
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" outlined>
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarColor" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>

export default {
  data: () => ({
    loading: false,
    search: '',
    selectedPermissions: [],
    snackbar: false,
    timeout: 2000,
    snackbarMessage: '',
    snackbarColor: '',
    showPopup: false,
  }),
  computed: {

    selected() {
      if (!this.users.length) return undefined
      const id = this.active[0];
      const selectedItem = this.users.find(user => user.id === id)
      return selectedItem
    },

    // this area is for permissions selection and edit permision
    allSelected() {
      return this.selectedPermissions.length === this.actionPermissions.length
    },
    categories() {
      const search = this.search.toLowerCase()
      if (!search) return this.actionPermissions
      return this.actionPermissions.filter(item => {
        const text = item.text.toLowerCase()
        return text.indexOf(search) > -1
      })
    },
    selections() {
      const selections = []
      for (const selection of this.selectedPermissions) {
        selections.push(selection)
      }
      return selections
    },

  },
  watch: {
		selected: {
			handler(newSelected) {
			if (newSelected) {
				const filteredObjects = this.actionPermissions.filter(obj1 => {
				return newSelected.action_permissions.some(obj2 => obj2.id === obj1.id);
				});
				this.selectedPermissions = filteredObjects;
			} else {
				this.selectedPermissions = [];
			}
			},
			immediate: true
		},
		selectedPermissions() {
			this.search = '';
		}
},

  props: {

    currentServer: Object,
    propValue: String,

    actionPermissions: {
      type: Array,
      required: true
    },
    active: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  methods: {
    openPopup(message) {
      this.message = message;
      this.showPopup = true;
    },
    handleConfirm() {
      this.showPopup = false;
    },

    async fetchActionPermissions(id, item) {

      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = this.currentServer?.baseUrl
        const token = localStorage.getItem('token');

        const response = await fetch(`${baseurl}/admin/roles/${id}/show_action_permissions`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const TableData = data?.map((item) => {

          return {
            id: item?.id,
            name: item?.name,
            permissions: item?.permissions,
          }

        })
        item.children.splice(0)
        item.children.push(...TableData);
        this.actionPermissions = TableData;

      } catch (error) {
        console.error('Error fetching action permissions:', error);
      }
    },

    async save(id) {

      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = this.currentServer?.baseUrl
        const token = localStorage.getItem('token');
        const menuIdArray = this.selectedPermissions.map((item) => item.id)
        var raw = JSON.stringify({
          'action_permission_ids': menuIdArray
        });
        const response = await fetch(`${baseurl}admin/roles/${id}/add_action_permission`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: raw,

        });
        await this.fetchActionPermissions(id);

        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }

        this.snackbar = true;
        this.snackbarColor = 'green';
        this.snackbarMessage = 'Save Successful';
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'red';
          this.snackbarMessage = error.message;
        }
      }
    },
  },
}
</script>
